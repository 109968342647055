import { baseUrl } from "./baseUrl";
import { get, post } from "./http";

// logo
export const queryPageInfo = (param) =>
get(baseUrl + "/blade-zb/index/queryPageInfo", param);

// 滚动通知
export const getDynamicNews = (param) =>
  get(baseUrl + "/blade-zb/index/getDynamicNews", param);
// 通知公告
export const getNoticeData = (param) =>
  get(baseUrl + "/blade-zb/index/getNoticeData", param);
// 自行采购公告
export const getNoticeList = (param) =>
  get(baseUrl + "/blade-zb/index/getNoticeList", param);
